<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback />  {{ $t('Appointment Report') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-row class="px-2">
        <v-col
          cols="12"
          md-6
          lg="3"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            dense
            outlined
            :label="`${$t('search')} (${$t('pressEnterForSearch')})`"
            @keypress.enter="addPayload(payload.start, payload.end)"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md-6
          lg="3"
          class="py-0"
        >
          <v-select
            v-model="appointmentSelect"
            :items="appointmentList"
            :label="$t('status')"
            dense
            outlined
            :item-text="$i18n.locale"
            item-value="value"
            @change="addPayload(payload.start, payload.end)"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import AppointmentStatus from '@/@fake-db/data/AppointmentStatus.json'
import { i18n } from '@/plugins/i18n'
import { reportAppoint, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate } from '@/plugins/filters'

export default {
  components: {
    DateFilters,
    Comeback,
  },
  setup() {
    const appointmentList = ref(AppointmentStatus.data)
    const appointmentSelect = ref('')
    const searchtext = ref('')
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('date_of_appointment'), value: 'appoint_date', width: 120 },
      { text: i18n.t('time'), value: 'appoint_start', width: 100 },
      { text: i18n.t('to'), value: 'appoint_end', width: 100 },
      { text: i18n.t('customer_code'), value: 'customer_id', width: 120 },
      { text: i18n.t('customer_patient'), value: 'customer_fullname', width: 250 },
      { text: i18n.t('tel'), value: 'appoint_tel', width: 120 },
      { text: i18n.t('doctor_appointment'), value: 'user_fullname', width: 250 },
      { text: i18n.t('status'), value: 'appoint_status_name', width: 120 },
      { text: i18n.t('status_sms'), value: 'appoint_status_sms', width: 120 },
      { text: i18n.t('topic'), value: 'appoint_topic', width: 120 },
      { text: i18n.t('note'), value: 'appoint_note', width: 200 },
    ])
    const options = ref({})
    const exportLoading = ref(false)
    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        appoint_status_id: appointmentSelect.value,
        searchtext: searchtext.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { data } = await reportAppoint(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('AppointmentReport')} ${i18n.t(
        'since',
      )} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} .xlsx`
      const dataExport = await removeComma(
        JSON.parse(JSON.stringify(dataTableList.value)),
      )

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [
          `${i18n.t('AppointmentReport')} ${i18n.t(
            'since',
          )} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} `,
        ],
        [
          '#',
          `${i18n.t('date_of_appointment')}`,
          `${i18n.t('time')}`,
          `${i18n.t('to')}`,
          `${i18n.t('customer_code')}`,
          `${i18n.t('customer_patient')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('doctor_appointment')}`,
          `${i18n.t('status')}`,
          `${i18n.t('status_sms')}`,
          `${i18n.t('topic')}`,
          `${i18n.t('note')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'appoint_date',
          'appoint_start',
          'appoint_end',
          'customer_id',
          'customer_fullname',
          'appoint_tel',
          'user_fullname',
          'appoint_status_name',
          'appoint_status_sms',
          'appoint_topic',
          'appoint_note',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 11 } }]
      const wscols = [
        { wch: 8 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 18 },
        { wch: 20 },
        { wch: 12 },
        { wch: 12 },
        { wch: 15 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${i18n.t('AppointmentReport')}`,
      )

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      loading,
      dataTableList,
      columns,
      options,
      exportLoading,
      payload,
      addPayload,
      exportExcel,
      appointmentList,
      appointmentSelect,
      searchtext,
      mdiFileExcelOutline,
    }
  },
}
</script>
